module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://staging-marronelaw2018.kinsta.cloud/graphql","schema":{"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nextlevel-static","short_name":"nextlevel","start_url":"/","background_color":"#0d0d0d","theme_color":"#b19d5d","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6fa5d042c0d17237a4ce65d15668a1ef"},
    }]
