// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-giveaway-js": () => import("./../../../src/templates/giveaway.js" /* webpackChunkName: "component---src-templates-giveaway-js" */),
  "component---src-templates-media-library-js": () => import("./../../../src/templates/media-library.js" /* webpackChunkName: "component---src-templates-media-library-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-white-paper-js": () => import("./../../../src/templates/white-paper.js" /* webpackChunkName: "component---src-templates-white-paper-js" */),
  "component---src-templates-white-papers-index-js": () => import("./../../../src/templates/white-papers-index.js" /* webpackChunkName: "component---src-templates-white-papers-index-js" */)
}

